<template>
  <div>
    <ContentGenerator />
    <SearchAndAnalyze @search-results="updateResults" />
    <ResultsDisplay :results="searchResults" />
    <KeywordData />
  </div>
</template>

<script>
import ContentGenerator from "./ContentGenerator.vue";
import SearchAndAnalyze from "./SearchAndAnalyze.vue";
import ResultsDisplay from "./ResultsDisplay.vue";
import KeywordData from "./KeywordData.vue";

export default {
  components: {
    ContentGenerator,
    SearchAndAnalyze,
    ResultsDisplay,
    KeywordData
  },
  data() {
    return {
      searchResults: []
    };
  },
  methods: {
    updateResults(data) {
      this.searchResults = data;
    }
  }
};
</script>
