<template>
  <div>
    <div v-for="result in results" :key="result.url" class="result">
      <h2><a :href="result.url">{{ result.title }}</a></h2>
      <p>{{ result.snippet }}</p>
      <p>Content: {{ result.content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    results: {
      type: Array,
      default: () => []
    }
  }
};
</script>