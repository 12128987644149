<template>
  <div class="keyword-generator">
    <div class="input-group">
      <input type="text" v-model="keywords" placeholder="Enter keywords, separated by commas"/>
      <button @click="generateKeywordIdeas">Generate Keyword Ideas</button>
    </div>
    <div v-if="loading" class="loading">Generating keyword ideas...</div>
    <ul v-if="keywordIdeas.length">
      <li v-for="(idea, index) in keywordIdeas" :key="index">{{ idea }}</li>
    </ul>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
import axios from 'axios'; // Make sure axios is installed

export default {
  data() {
    return {
      keywords: '',
      keywordIdeas: [],
      loading: false,
      error: ''
    };
  },
  methods: {
    async generateKeywordIdeas() {
      this.loading = true;
      this.error = '';
      this.keywordIdeas = [];

      try {
        const response = await axios.post('/api/generate-keyword-ideas', {
          keywords: this.keywords.split(',').map(keyword => keyword.trim()) 
        });

        this.keywordIdeas = response.data.keywordIdeas; 
      } catch (error) {
        console.error("There was an error!", error);
        this.error = 'Failed to generate keyword ideas. Please try again.';
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.input-group {
  margin-bottom: 20px;
}

.loading {
  color: #888;
}

.error {
  color: red;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #f9f9f9;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ccc;
}
</style>