<template>
  <div>
    <form @submit.prevent="searchAndAnalyze">
      <input v-model="searchQuery" type="text" placeholder="Enter your search query">
      <button type="submit">Query SERPs</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SearchAndAnalyze',
  data() {
    return {
      searchQuery: ''
    };
  },
  methods: {
    async searchAndAnalyze() {
      try {

        let formData = new FormData();
        formData.append('search_query', this.searchQuery);

        const response = await axios.post('/api/search_and_analyze', formData);

        this.$emit('search-results', response.data);
      } catch (error) {
        console.error('Error during search and analysis:', error);
      }
    }
  }
};
</script>
