<template>
  <div>
    <form @submit.prevent="generateContent">
      <textarea v-model="prompt" rows="5" placeholder="Enter your prompt"></textarea>
      <button type="submit">Generate</button>
    </form>
    <div v-if="generatedContent">
      <h3>Generated Content:</h3>
      <p>{{ generatedContent }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      prompt: '',
      generatedContent: ''
    };
  },
  methods: {
    async generateContent() {
      try {
        const response = await axios.post('/api/generate_content', { prompt: this.prompt });
        this.generatedContent = response.data.choices[0].text.trim();
      } catch (error) {
        console.error('Error generating content:', error);
      }
    }
  }
};
</script>
